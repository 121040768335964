<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages" />
    <div class="flex flex-col p-6 flex-1">
      <div class="flex flex-wrap mb-2 mt-3 border-b pb-2 justify-end" >
        <button class="bg-green-800 text-white px-4 py-2" @click="onCreate">
          Create User
        </button>
      </div>
      <ResourceTable
        :disableActions="false"
        name="UserTable"
        :variables="{}"
        :columns="columns"
        :page="1"
        queryKey="listUsers"
        :query="ResultQuery"
      />
    </div>
  </div>
</template>

<script>
import ResourceTable from '@/components/ResourceTable';
import moment from 'moment-timezone';
import {listUsers} from '../../graphql/queries';

const columns = [
  {
    name: 'username',
    text: 'Name',
    render: item => item?.username,
  },
  {
    name: 'updated',
    text: 'Updated',
    render: item => moment(item?._lastChangedAt).toString(),
  },
];

export default {
  components: {
    ResourceTable,
  },
  computed: {
    ResultQuery() {
      return listUsers;
    },
    columns() {
      return columns;
    },
    pages() {
      return [
        {
          name: 'Users',
          to: {name: 'users'},
          current: true,
        },
      ];
    },
  },
  methods: {
    onCreate() {
      this.$router.push({
        name: 'users.create',
      });
    },
  },
  mounted() {},
};
</script>
